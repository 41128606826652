<template>
    <div class="height__full">
        <div class="app-modal_In">
            <div class="app-modal__header d-flex f-between p-5 m-title-modal">
                <p class="large--title m-0">{{ $t("message.new_m", { m: $t("message.container") }) }}</p>
                <div class="d-flex">
                    <el-button
                        type="success"
                        size="medium"
                        class="mr-3"
                        :loading="loadingButton"
                        :disabled="loadingButton ? true : false"
                        @click="submit(true, true)"
                        plain
                    >
                        {{ $t("message.save_and_continue") }}
                    </el-button>
                    <crm-store-update-close
                        :permission="$options.name"
                        :button_type="'store'"
                        :loading="loadingButton"
                        @c-submit="submit(true)"
                        @c-close="close()"
                    ></crm-store-update-close>
                </div>
            </div>
        </div>
        <div class="px-3 py-4">
            <div
                class="
                    gc-card
                    rounded-sm
                    mb-4
                    p-relative
                    mr-3
                "
            >
                <div class="gc-card__body px-3 py-2">
                    <div class="text-descr">
                        <span class="tracking__bold font20">
                            {{ $t('message.container_info') }}
                        </span>
                        <span class="text-danger">
                            ({{  $t('message.autosave function available only while updating container') }})
                        </span>
                        <el-row :gutter="20"> 
                            <el-form :model="form" :rules="rules" ref="form" class="w-100" label-position="top">
                                <el-col class="height__full" :span="8">
                                    <el-form-item :label="$t('message.containerType')" prop="container_type_id">
                                        <select-container-type
                                            :size="'large'"
                                            :add_query="{user_id: authUser.id}"
                                            :placeholder="$t('message.containerType')"
                                            :id="form.container_type_id"
                                            v-model="form.container_type_id"
                                        >
                                        </select-container-type>
                                    </el-form-item>
                                </el-col>
                                <el-col class="height__full" :span="8">
                                    <el-form-item :label="$t('message.from_where')" prop="from_filial_id">
                                        <select-from-filial 
                                            v-model="form.from_filial_id" 
                                            :id="form.from_filial_id" 
                                            :select_only="true"
                                            :placeholder="$t('message.from_where')" 
                                            class="select__width w-100" 
                                        />
                                    </el-form-item>
                                </el-col>
                                <el-col class="height__full" :span="8"> 
                                    <el-form-item :label="$t('message.to_where')" prop="to_filial_id">
                                        <select-to-filial 
                                            v-model="form.to_filial_id" 
                                            :id="form.to_filial_id" 
                                            :show_all="1"
                                            :placeholder="$t('message.to_where')" 
                                            class="select__width w-100" 
                                        />
                                    </el-form-item>
                                </el-col>
                            </el-form>
                        </el-row>  
                        <el-row :gutter="20" class="mb-5" v-if="form.from_filial_id && form.to_filial_id">
                            <el-col :span="12">
                                <select-client
                                    :size="'large'"
                                    class="w-100"
                                    :placeholder="$t('message.client')"
                                    :id="form.all_client_id"
                                    :query="{from_filial_id: form.from_filial_id, to_filial_id: form.to_filial_id}"
                                    v-model="form.all_client_id"
                                    >
                                </select-client>
                            </el-col>
                            <el-col :span="12">
                                <select-deal
                                    v-model="deal_id"
                                    :id="deal_id"
                                    :from_filial_id="form.from_filial_id"
                                    :to_filial_id="form.to_filial_id"
                                    :all_client_id="form.all_client_id"
                                    :query="{has_in_sending_filial: true}"
                                    :size="'large'"
                                    :placeholder="$t('message.deal')"
                                ></select-deal>
                            </el-col>
                        </el-row>
                            
                    </div>
                </div>
            </div>
        </div>
        <el-row>   
            <el-col :span="24">
                <div class="gc-card__body px-3 py-4 mm_custom_input_padding">
                    <div class="app-form__group mb-0">
                        <div
                            class=" 
                                gc-card
                                rounded-sm
                                mb-4
                                p-relative
                                mr-3
                                px-3 py-3
                            "
                        >
                            <div>
                                <el-table 
                                    header-row-class-name="header__class"
                                    row-class-name="row__class"
                                    :data="dealProductsList" 
                                    show-summary 
                                    :summary-method="getSummaries" 
                                    border stripe 
                                    style="width: 100%">
                                    <el-table-column
                                        type="index"
                                        width="50">
                                    </el-table-column>
                                    <el-table-column :label="$t('message.deal')" width="90">
                                        <template slot-scope="scope"> 
                                            <span v-show="scope.row.type === 'product'">{{ scope.row.deal_id }}</span>
                                            <span v-show="scope.row.type === 'package'">
                                                {{  scope.row.deal_id ? scope.row.deal_id : (scope.row.deals ? scope.row.deals.map(el => el.id).toString() : '') }}
                                            </span>
                                        </template>
                                    </el-table-column>
                                    <el-table-column :label="$t('message.reciever')">
                                        <template slot-scope="scope"> 
                                            <span>
                                                <span v-if="scope.row.reciever">
                                                    {{'ID: ' + scope.row.reciever.custom_id }} <br> {{ scope.row.reciever.name}} 
                                                </span>
                                                <span v-else>
                                                    <span v-if="scope.row.client">
                                                        {{'ID: ' + scope.row.client.custom_id }} <br> {{ scope.row.client.name}} 
                                                    </span>
                                                </span>
                                            </span>
                                        </template>
                                    </el-table-column>
                                    <el-table-column :label="$t('message.name')">
                                        <template slot-scope="scope"> 
                                            <span v-show="scope.row.type === 'product'">{{ scope.row.product ? scope.row.product.name : '' }} </span>
                                            <span v-show="scope.row.type === 'package'">{{ $t('message.package') }} </span>
                                        </template>
                                    </el-table-column>
                                    <el-table-column :label="$t('message.barcode')">
                                        <template slot-scope="scope"> 
                                            <span>{{ scope.row.barcode }} </span>    
                                        </template>
                                    </el-table-column>
                                    <el-table-column :label="$t('message.quantity_y')" width="80">
                                        <template slot-scope="scope"> 
                                            <span>{{ scope.row.remainder }} </span>    
                                            <span v-if="!scope.row.batches"> 1 </span>    
                                        </template>
                                    </el-table-column>
                                    <el-table-column :label="$t('message.current_quantity')" width="95">
                                        <template slot-scope="scope">
                                            <div v-show="!scope.row.totalQuantity">
                                                <el-input
                                                    :disabled="(scope.row.batches && scope.row.batches.length > 1)"
                                                    :min="0"
                                                    type="number"
                                                    size="mini"
                                                    @input="checkValue(scope.row)"
                                                    v-model="scope.row.incoming_quantity"
                                                ></el-input>
                                            </div>
                                            <span v-show="scope.row.totalQuantity">
                                                {{scope.row.totalQuantity}}
                                            </span>
                                        </template>
                                    </el-table-column>

                                    <el-table-column :label="columns.weight.title"  width="130">
                                        <template slot-scope="scope">
                                            <div v-show="scope.row.type === 'product' && (!scope.row.batches || scope.row.batches.length <=1 )">
                                                <el-input 
                                                    class="mm_custom_input"
                                                    :min="0" 
                                                    :disabled="showInputCheck(scope.row)"
                                                    @input="updatePoundAndKG(scope.row, 'weight_in_kg')"
                                                    type="number" 
                                                    size="mini" 
                                                    v-model="scope.row.weight"
                                                >
                                                    <template slot="append">{{ $t('message.kg') }}</template>
                                                </el-input>

                                                <el-input
                                                    type="text"
                                                    size="mini"
                                                    disabled
                                                    :value="compareAndSetMeasureWord(scope.row)"
                                                ></el-input>
                                            </div>

                                            <span v-show="scope.row.type === 'package'"> 
                                                {{ scope.row.total_weight }}  /  {{ parseFloat(scope.row.total_weight * $kg_to_pound).toFixed(3) }}
                                            </span>
                                            <span v-show="scope.row.type === 'product' && scope.row.batches && scope.row.batches.length > 1"> 
                                                {{ showWeight(scope.row) }}  /  {{ parseFloat((showWeight(scope.row) * $kg_to_pound)).toFixed(3) }}
                                            </span>
                                        </template>
                                    </el-table-column>

                                    <el-table-column :label="columns.item_weight.title"  width="130">
                                        <template slot-scope="scope">
                                            <el-input 
                                                v-show="scope.row.type === 'product' && (!scope.row.batches || scope.row.batches.length <=1 )"
                                                class="mm_custom_input"
                                                :min="0" 
                                                @input="updatePoundAndKG(scope.row, 'weight_in_kg')"
                                                type="number" 
                                                size="mini" 
                                                v-model="scope.row.item_weight"
                                            >
                                                <template slot="append">{{ $t('message.kg') }}</template>
                                            </el-input>

                                            <el-input 
                                                v-show="scope.row.type === 'product' && (!scope.row.batches || scope.row.batches.length <=1 )"
                                                class="mt-1 mm_custom_input"
                                                :min="0" 
                                                @input="updatePoundAndKG(scope.row, 'weight_in_pounds')"
                                                type="number" 
                                                size="mini" 
                                                v-model="scope.row.item_weight_in_pounds"
                                            >
                                                <template slot="append">{{ $t('message.lbs') }}</template>
                                            </el-input>
                                        </template>
                                    </el-table-column>

                                    <el-table-column :label="columns.width.title + ' x ' + columns.height.title + ' x ' + columns.length.title" width="250">
                                        <template slot-scope="scope">
                                            <div class="d-flex mm-input-without-padding">
                                                <el-input
                                                    v-if="scope.row.type &&  scope.row.type === 'product'"
                                                    class="mm_custom_input"
                                                    :min="0"
                                                    type="number"
                                                    size="mini"
            
                                                    @input="updateWeightAndSizes(scope.row, 'width')"
                                                    v-model="scope.row.width"
                                                >
                                                </el-input>
                                                <el-input
                                                    v-if="scope.row.type &&  scope.row.type === 'product'"
                                                    class="mm_custom_input"
                                                    :min="0"
                                                    type="number"
                                                    size="mini"
            
                                                    @input="updateWeightAndSizes(scope.row, 'height')"
                                                    v-model="scope.row.height"
                                                >
                                                </el-input>
                                                <el-input
                                                    v-if="scope.row.type && scope.row.type === 'product'"
                                                    class="mm_custom_input"
                                                    :min="0"
                                                    type="number"
                                                    size="mini"
            
                                                    @input="updateWeightAndSizes(scope.row, 'length')"
                                                    v-model="scope.row.length"
                                                >
                                                </el-input>
                                                <el-button 
                                                    v-if="scope.row.type && scope.row.type === 'product'"  
                                                    style="border-radius: 5px !important; background-color: #F5F7FA;"
                                                    size="mini" 
                                                    disabled>
                                                        {{ $t('message.sm') }}
                                                </el-button>
                                            </div>
                                            <div class="d-flex mm-input-without-padding">
                                                <el-input
                                                    v-if="scope.row.type &&  scope.row.type === 'product'"
                                                    class="mm_custom_input"
                                                    :min="0"
                                                    type="number"
                                                    size="mini"
            
                                                    @input="updateWeightAndSizes(scope.row, 'width_on_inches')"
                                                    v-model="scope.row.width_on_inches"
                                                >
                                                </el-input>
                                                <el-input
                                                    v-if="scope.row.type &&  scope.row.type === 'product'"
                                                    class="mm_custom_input"
                                                    :min="0"
                                                    type="number"
                                                    size="mini"
            
                                                    @input="updateWeightAndSizes(scope.row, 'height_on_inches')"
                                                    v-model="scope.row.height_on_inches"
                                                >
                                                </el-input>
                                                <el-input
                                                    v-if="scope.row.type &&  scope.row.type === 'product'"
                                                    class="mm_custom_input"
                                                    :min="0"
                                                    type="number"
                                                    size="mini"
            
                                                    @input="updateWeightAndSizes(scope.row, 'length_on_inches')"
                                                    v-model="scope.row.length_on_inches"
                                                >
                                                </el-input>
                                                <el-button 
                                                    v-if="scope.row.type && scope.row.type === 'product'"      
                                                    style="border-radius: 5px !important; background-color: #F5F7FA;"
                                                    size="mini" 
                                                    disabled>
                                                    {{ $t('message.inch') }}
                                                </el-button>
                                            </div>
                                        </template>
                                    </el-table-column>

                                    <el-table-column :label="$t('message.comment')">
                                        <template slot-scope="scope"> 
                                            <span v-show="scope.row.hasOwnProperty('comment')"> {{ scope.row.comment }} </span>
                                            <span  v-show="!scope.row.hasOwnProperty('comment')">{{scope.row.containerType ? scope.row.containerType.name : ''}}</span>

                                        </template>
                                    </el-table-column>

                                    <el-table-column :label="$t('message.services')" width="85">
                                        <template slot-scope="scope">
                                            <el-button @click="showServices(scope.row.deal_id)" type="primary" icon="fa-solid fa-tag" circle></el-button>  
                                        </template>
                                    </el-table-column>

                                    <el-table-column :label="$t('message.update')" width="85">
                                        <template slot-scope="scope"> 
                                            <el-button v-if="scope.row.type == 'product' && scope.row.batches && scope.row.batches.length > 1"
                                                @click="showAndUpdateBatches(scope.row)" 
                                                type="primary" icon="el-icon-edit" circle>
                                            </el-button> 
                                            <el-button v-if="scope.row.type == 'package'"
                                                @click="showContainerProducts(scope.row)" 
                                                type="primary" icon="el-icon-view" circle>
                                            </el-button> 
                                            <el-button v-if="scope.row.type == 'product' && scope.row.parcel == true"
                                                @click="updateParcelProducts(scope.row)" 
                                                type="primary" icon="el-icon-view" circle>
                                            </el-button> 
                                        </template>
                                    </el-table-column>

                                    <el-table-column width="50">
                                        <template slot-scope="scope">
                                            <i @click="removeFromDealProductsList(scope.row.id, scope.$index)" class="el-icon-delete icon__delete"></i>
                                        </template>
                                    </el-table-column>
                                </el-table>
                            </div>
                            <div class="mt-4 pallet__flex">
                                <select-product
                                    :size="'medium'"
                                    class="w-100 mr-5"
                                    :placeholder="$t('message.product_id')"
                                    :deal_id="deal_id"
                                    :id="product_id"
                                    :from_filial_id="form.from_filial_id"
                                    :to_filial_id="form.to_filial_id"
                                    :all_client_id="form.all_client_id"
                                    v-model="product_id"
                                    @updateDealAndAddProduct="updateDealAndAddProduct"
                                    @get_selected_product="get_selected_product"
                                >
                                </select-product>
                            </div>
                        </div>
                    </div>
                </div>
            </el-col>
        </el-row>
        
        <!-- Product partiyalar bo'yicha -->
        <el-dialog 
            :append-to-body="true"
            width="80%"
            :title="$t('message.product_inventorization')" 
            :visible.sync="batchDialog">
            <div v-if="selectedProduct.batches" class="mm_custom_input_padding">
                <el-table :data="selectedProduct.batches" border stripe>
                    <el-table-column
                        type="index"
                        width="50">
                    </el-table-column>
                   <el-table-column prop="deal_id" :label="$t('message.deal')" width="90"> </el-table-column> 
                   <el-table-column :label="$t('message.name')">
                        <template> {{ selectedProduct.product ? selectedProduct.product.name : '' }} </template>
                    </el-table-column>
                    
                    <el-table-column :label="$t('message.barcode')">
                        <template slot-scope="scope"> {{ scope.row.barcode }} </template>
                    </el-table-column>

                    <el-table-column :label="$t('message.quantity_y')"  width="80">
                        <template slot-scope="scope"> {{ scope.row.remainder }} </template>
                    </el-table-column>
                    
                    <el-table-column :label="$t('message.current_quantity')" width="95">
                        <template slot-scope="scope">
                            <el-input
                                :min="0"
                                type="number"
                                size="mini"
                                @input="checkValue(scope.row)"
                                v-model="scope.row.incoming_quantity"
                            ></el-input>
                        </template>
                    </el-table-column>

                    <el-table-column :label="columns.weight.title" width="95">
                        <template slot-scope="scope">
                            <el-input 
                                class="mm_custom_input"
                                :min="0" 
                                @input="updatePoundAndKG(scope.row, 'weight_in_kg')"
                                type="number" 
                                size="mini" 
                                v-model="scope.row.weight"
                            >
                                <template slot="append">{{ $t('message.kg') }}</template>
                            </el-input>
                            <el-input
                                type="text"
                                size="mini"
                                disabled
                                :value="compareAndSetMeasureWord(scope.row)"
                            ></el-input>
                        </template>
                    </el-table-column>

                    <el-table-column :label="columns.item_weight.title" width="95">
                        <template slot-scope="scope">
                            <el-input 
                                class="mm_custom_input"
                                :min="0" 
                                @input="updatePoundAndKG(scope.row, 'weight_in_kg')"
                                type="number" 
                                size="mini" 
                                v-model="scope.row.item_weight"
                            >
                                <template slot="append">{{ $t('message.kg') }}</template>
                            </el-input>

                            <el-input 
                                class="mt-1 mm_custom_input"
                                :min="0" 
                                type="number" 
                                size="mini" 
                                @input="updatePoundAndKG(scope.row, 'weight_in_pounds')"
                                v-model="scope.row.weight_in_pounds"
                            >
                                <template slot="append">{{ $t('message.lbs') }}</template>
                            </el-input>
                        </template>
                    </el-table-column>

                    <el-table-column :label="columns.width.title + ' x ' + columns.height.title + ' x ' + columns.length.title" width="250">
                        <template slot-scope="scope">
                            <div class="d-flex mm-input-without-padding">
                                <el-input
                                    v-if="scope.row.type &&  scope.row.type === 'product'"
                                    class="mm_custom_input"
                                    :min="0"
                                    type="number"
                                    size="mini"
                                    @input="updateWeightAndSizes(scope.row, 'width')"
                                    v-model="scope.row.width"
                                >
                                </el-input>
                                <el-input
                                    v-if="scope.row.type &&  scope.row.type === 'product'"
                                    class="mm_custom_input"
                                    :min="0"
                                    type="number"
                                    size="mini"
                                    @input="updateWeightAndSizes(scope.row, 'height')"
                                    v-model="scope.row.height"
                                >
                                </el-input>
                                <el-input
                                    v-if="scope.row.type && scope.row.type === 'product'"
                                    class="mm_custom_input"
                                    :min="0"
                                    type="number"
                                    size="mini"
                                    @input="updateWeightAndSizes(scope.row, 'length')"
                                    v-model="scope.row.length"
                                >
                                </el-input>
                                <el-button 
                                    v-if="scope.row.type && scope.row.type === 'product'"  
                                    style="border-radius: 5px !important; background-color: #F5F7FA;"
                                    size="mini" 
                                    disabled>
                                        {{ $t('message.sm') }}
                                </el-button>
                            </div>
                            <div class="d-flex mm-input-without-padding">
                                <el-input
                                    v-if="scope.row.type &&  scope.row.type === 'product'"
                                    class="mm_custom_input"
                                    :min="0"
                                    type="number"
                                    size="mini"
                                    @input="updateWeightAndSizes(scope.row, 'width_on_inches')"
                                    v-model="scope.row.width_on_inches"
                                >
                                </el-input>
                                <el-input
                                    v-if="scope.row.type &&  scope.row.type === 'product'"
                                    class="mm_custom_input"
                                    :min="0"
                                    type="number"
                                    size="mini"
                                    @input="updateWeightAndSizes(scope.row, 'height_on_inches')"
                                    v-model="scope.row.height_on_inches"
                                >
                                </el-input>
                                <el-input
                                    v-if="scope.row.type &&  scope.row.type === 'product'"
                                    class="mm_custom_input"
                                    :min="0"
                                    type="number"
                                    size="mini"
                                    @input="updateWeightAndSizes(scope.row, 'length_on_inches')"
                                    v-model="scope.row.length_on_inches"
                                >
                                </el-input>
                                <el-button 
                                    v-if="scope.row.type && scope.row.type === 'product'"      
                                    style="border-radius: 5px !important; background-color: #F5F7FA;"
                                    size="mini" 
                                    disabled>
                                    {{ $t('message.inch') }}
                                </el-button>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column :label="$t('message.comment')">
                        <template slot-scope="scope"> {{ scope.row.comment }} </template>
                    </el-table-column>
                </el-table>

                <div class="mt-4 cargo__right">
                    <el-button @click="updateIncomingProducts()" type="primary" plain>{{ $t('message.save') }}</el-button>
                </div>
            </div>
        </el-dialog>

        <!-- Upakovka productlari -->
        <el-dialog class="dialog__modal" :title="$t('message.products')" :visible.sync="dialogProductsList" width="60%" @closed="emptyCortainerProducts()" :append-to-body="true" >
            <div v-loading="loadingProducts">
                <el-table :data="containerProducts">
                    <el-table-column
                        type="index"
                        width="50">
                    </el-table-column>
                    <el-table-column prop="name" :label="$t('message.name')">
                        <template slot-scope="scope">
                            {{ scope.row.product ? scope.row.product.name : '' }}
                        </template>
                    </el-table-column>

                    <el-table-column prop="comment" :label="$t('message.comment')">
                        <template slot-scope="scope">
                            {{ scope.row.comment ? scope.row.comment : '' }}
                        </template>
                    </el-table-column>

                    <el-table-column prop="barcode" :label="$t('message.barcode')"></el-table-column>
                    <el-table-column prop="remainder" :label="$t('message.quantity')"></el-table-column>

                    <el-table-column prop="weight" :label="$t('message.weight_in_kg')"></el-table-column>
                    <el-table-column prop="weight_in_pounds" :label="$t('message.weight_in_pounds')"></el-table-column>
                </el-table>
            </div>
        </el-dialog>

         <!-- Product posilki -->
        <el-dialog 
            class="dialog__modal" 
            :title="$t('message.products')" 
            :visible.sync="dialogParcelProductsList" 
            :append-to-body="true"
            width="60%" 
            @opened="$refs['parcelProducts'] ? $refs['parcelProducts'].afterOpen() : ''">
            <parcel-products-list ref="parcelProducts" :selectedItem="selectedProd"></parcel-products-list>
        </el-dialog>

        <showDealServices :deal_id="deal_id_services"></showDealServices>

    </div>
</template>
    
<script>
import selectCargo from "@/components/inventory/select-cargo";
import selectProduct from "@/components/inventory/select-deal-filial-product";
import selectDeal from "@/components/selects/select-deal";
import selectContainerType from "@/components/inventory/select-container-type";
import selectFromFilial from "@/components/selects/select-from-filial";
import selectToFilial from "@/components/selects/select-to-filial";
import selectClient from "@/components/inventory/select-client";
import parcelProductsList from "@/views/waiting_product/components/parcel-products-list";
import showDealServices from "@/views/services/show-deal-services";

import { mapGetters, mapActions } from "vuex";
import drawer from "@/utils/mixins/drawer";
import form from "@/utils/mixins/form";
import { formatMoney, formatNumber } from "@/filters/index";
import product_calculation_functions from "@/utils/mixins/product_calculation_functions";
import { successVoice, errorVoice } from '@/utils/audioHelper';

export default {
    mixins: [form, drawer, product_calculation_functions],
    components: {
        selectCargo,
        selectProduct,
        selectContainerType,
        selectDeal,
        selectFromFilial,
        selectToFilial,
        selectClient,
        parcelProductsList,
        showDealServices
    },
    data: () => ({
        deal_id_services: null,
        mode: true,
        deal_id: null,
        product_id: null,
        dealProductsList: [],
        batchDialog: false,
        selectedProduct: {},
        loadingProducts: false,
        dialogProductsList: false,
        dialogParcelProductsList: false,
        selectedProd: {}
    }),
    watch: {
        "form.to_filial_id": {
            handler: async function(newVal, oldVal) {
                this.dealProductsList = [];
            },
            deep: true,
            immediate: true
        },
    },
    computed: {
        ...mapGetters({
            containerProducts: "packageForProductCirculations/products",
            authUser: "auth/user",
            rules: "containers/rules",
            model: "containers/model",
            columns: "dealProducts/columns",
        }),
        total_weight: function () {
            let total = 0;
            this.dealProductsList.forEach((product) => {
                if(product.type === 'product'){
                    if(product.batches && product.batches.length > 1){
                        product.batches.forEach(batch => {
                            total += parseFloat(batch.weight);
                        });
                    }else{
                        total += parseFloat(product.weight);
                    }
                }
                if(product.type === 'package'){
                    total += parseFloat(product.total_weight);
                }
                
            });
            return _.round(parseFloat(total) ,2);
        },
    },
    methods: {
        ...mapActions({
            save: "containers/store",
            showProducts: "packageForProductCirculations/showProducts",
            emptyCortainerProducts: "packageForProductCirculations/empty",
        }),
        showServices(deal_id) {
            this.deal_id_services = deal_id;
            setTimeout(() => {
                this.deal_id_services = null;
            }, 500);
        },
        updateParcelProducts(parcel) {
            this.selectedProd = parcel;
            this.dialogParcelProductsList = true;
        },
        updateDealAndAddProduct(val) {
            // if(val){
            //     this.deal_id = val;
            // }
        },
        afterLeave(){
            this.deal_id = null;
            this.product_id = null;
            this.dealProductsList = [];
        },
        async updateIncomingProducts(){
            await this.updateBatches();
            this.selectedProduct = {};
            this.batchDialog = false;
        },
        showContainerProducts(pack){
            this.dialogProductsList = true;
            this.loadingProducts = true;
            this.showProducts(pack.id).then(res => {
                    this.loadingProducts = false;
                }).catch(err => {
                    this.loadingProducts = false;
                });
        },
        updateBatches(){
            if(this.selectedProduct && this.selectedProduct.batches){
                this.dealProductsList.forEach(el => {
                    if(el.id === this.selectedProduct.id){
                        this.$set(el, 'batches', this.selectedProduct.batches);
                        
                        let total_quantity = 0;
                        this.selectedProduct.batches.forEach(batch => {
                            total_quantity +=  parseFloat(batch.incoming_quantity);
                        });
                       
                        this.$set(el, 'incoming_quantity', parseFloat(total_quantity));
                    }
                });
            }
        },
        showAndUpdateBatches(row){
            this.batchDialog = true;
            this.selectedProduct = JSON.parse(JSON.stringify(row));
        },
        showWeight(row){
            if(row.batches && row.batches.length > 1){
                let weights = 0;
                row.batches.forEach(element => {
                    weights += parseFloat(element.weight)
                });
                return _.round(weights, 3);
            }else{
                return row.weight;
            }
        },
        checkValue(row){
            if(parseFloat(row.incoming_quantity) > parseFloat(row.remainder) || parseFloat(row.incoming_quantity) < 0){
                this.$set(row, 'incoming_quantity', parseFloat(row.remainder))
            }
            if(!row.incoming_quantity){
                this.$set(row, 'incoming_quantity', parseFloat(0))
            }
        },
        showInputCheck(row){
            if(row.type === 'product' && (!row.batches || row.batches.length <= 1)) {
                return true;
            }
            return false;
        },
        get_selected_product({product, barcode, type, from_list = false}){
            if(type === 'product' && product && this.dealProductsList.filter(el => (el.id === product.id && el.type === type)).length === 0){
                let prod = JSON.parse(JSON.stringify(product));
                this.$set(prod, 'type', type);
                if(barcode && prod.batches && prod.batches.length <= 1){
                    successVoice();
                    this.$set(prod, 'incoming_quantity', parseFloat(1));
                }else if(barcode && prod.batches && prod.batches.length > 1){
                    successVoice();
                    let index = prod.batches.map(e => e.barcode).indexOf(barcode);
                    this.$set(prod, 'incoming_quantity', parseFloat(1));
                    this.$set(prod.batches[index], 'incoming_quantity', parseFloat(1));
                }else if(!from_list){
                    this.$set(prod, 'incoming_quantity', parseFloat(0));
                }

                this.dealProductsList.push(prod);
                this.product_id = null;
            }else if(type === 'product' && product && barcode){
                let index = this.dealProductsList.map(e => e.id, product.id).indexOf(product.id);
                if(this.dealProductsList[index].batches && this.dealProductsList[index].batches.length > 1){
                    this.dealProductsList[index].batches.forEach(el => {
                        if(el.barcode === barcode){
                            if(el.incoming_quantity < el.remainder) {
                                successVoice();
                                el.incoming_quantity ++;
                                this.dealProductsList[index].incoming_quantity ++;
                            }else{
                                this.insufficiant_prod_message();
                            }
                            
                        }
                    })
                }else{
                    if(this.dealProductsList[index].incoming_quantity < this.dealProductsList[index].remainder) {
                        successVoice();
                        this.dealProductsList[index].incoming_quantity ++;
                    }else{
                        this.insufficiant_prod_message();
                    }
                }
            }else if(type === 'package' && product && this.dealProductsList.filter(el => (el.id === product.id && el.type === type)).length === 0){
                successVoice();
                let prod = JSON.parse(JSON.stringify(product));
                this.$set(prod, 'type', type);
                this.dealProductsList.push(prod);
            }else if(!this.form.container_type_id || !this.form.from_filial_id || !this.form.to_filial_id){
                errorVoice();
                this.$refs['form'].validate((validate) => {});
                this.$notify({
                    title: this.$t('message.warning'),
                    type: "warning",
                    offset: 130,
                    message: this.$t('message.fill_the_required_fields')
                });
            }else if(!product){
                errorVoice();
                this.$notify({
                    title: this.$t('message.error_m'),
                    type: "error",
                    offset: 130,
                    message: this.$t('message.product_not_found_in_warehouse')
                });
            }else{
                errorVoice();
                this.$notify({
                    title: this.$t('message.product'),
                    type: "warning",
                    offset: 130,
                    message: this.$t('message.product_already_exists')
                });
            }
        },
        insufficiant_prod_message(){
            errorVoice();
            this.$notify({
                title: this.$t('message.product'),
                type: "error",
                offset: 130,
                message: this.$t('message.insufficient_goods')
            });
        },
        submit(close = true, goToUpdate = true) {
            this.form.products = this.dealProductsList;
            let check_empty_prods = this.dealProductsList.filter(el => (el.incoming_quantity && el.incoming_quantity === 0));
            if(this.dealProductsList.length > 0 && check_empty_prods.length === 0 && this.total_weight > 0){
                this.$refs["form"].validate((valid) => {
                    if (valid && this.validateIncomingQuantity()) {
                        this.loadingButton = true;
                        this.save(this.form)
                            .then((res) => {
                                this.loadingButton = false;
                                if(goToUpdate){
                                    this.$emit('openUpdate', res.data.result.container);
                                }
                                this.$alert(res);
                                this.parent().listChanged();
                                if (close) this.close();
                            })
                            .catch((err) => {
                                this.loadingButton = false;
                                this.$alert(err);
                            });
                    }
                });
            }else{
                this.$notify({
                    title: this.$t('message.warning'),
                    type: "warning",
                    offset: 130,
                    message: this.$t('message.check_prod_quantity')
                });
            }
        },
        validateIncomingQuantity(){
            let product_qty = 0, package_qty = 0;
            
            this.form.products.forEach(item => {
                if (item.type == 'product') {
                    product_qty += item.incoming_quantity;
                }
                else package_qty += parseInt(item.totalQuantity);
            }); 

            let all_quantity = product_qty + package_qty;

            if (all_quantity <= 0) {
                this.$message({
                    message: this.$t("message.product_validation_error"),
                    type: "warning",
                    showClose: true,
                });
                return false;
            }
            return true;
        },
        removeFromDealProductsList(id, index) {
            if (!id) {
                this.$confirm(
                    this.$t('message.Are you sure you want to uninstall the product?'),
                    this.$t('message.warning'),
                    {
                    confirmButtonText: this.$t('message.yes'),
                    cancelButtonText: this.$t('message.no'),
                    type: "warning",
                    }
                )
                .then(() => {
                    this.destroy(id)
                    .then((res) =>{
                        this.dealProductsList.splice(index, 1);
                        this.$alert(res);
                    })
                    .catch((err) => {
                        this.$alert(err);
                    })
                })
                .catch(() => {
                    this.$message({
                        type: "warning",
                        message: this.$t('message.deleting_product_canceled'),
                    });
                });
            }else{
                this.dealProductsList.splice(index, 1);
            }
        },
        getSummaries(param) {
            const { columns } = param;
            const sums = [];
            columns.forEach((column, index) => {
                if (index === 1) {
                    sums[index] = this.$t('message.total');
                    return;
                }
                if (column.label === this.columns.weight.title) {
                    sums[index] = formatNumber(this.total_weight, 1) + ' ' + this.$t('message.kg');
                    return;
                }
               
                if (column.label === (this.columns.weight.title + ' %')) {
                    sums[index] = formatMoney(this.weight, 2);
                    return;
                }
            });

            return sums;
        },
    },
    
}
</script>
    